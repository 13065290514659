import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import StartProject from '../components/Common/StartProject'

const FAQ = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Често Поставувани Прашања"  
            />
            <div className="ptb-100">
            {/* <div className="section-title">
                    <h2>Frequently Asked Questions</h2>
            </div> */}
                <div className="container">
                    <div className="faq-accordion">
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Дали ми треба кредитна картичка за да користам Тафома?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p><strong>Не</strong>, можете да се придружите на Тафома бесплатно, сè што ви треба е е-меил адреса. Бесплатниот пакет што го нуди Тафома нема временски ограничувања. Доколку овој пакет ги задоволува потребите на вашиот тим, можете да го користите засекогаш, без никакви проблеми.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Каква е кривата на учење на апликацијата Тафома?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Многу лесна. Архитектонските компоненти на Тафома го спојуваат основното складиште и корисничкиот интерфејс. Со други зборови, ја развивме Тафома за да има интуитивен и лесен кориснички UI/UX дизајн, што ја прави платформата речиси самообјаснувачка.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Како да пристапите до апликацијата?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Можете лесно да пристапите до Тафома од било кој уред, ако сакате да користите Тафома преку прелистувач, само одете на <strong><a href="https://app.tafoma.com"><u>https://app.tafoma.com</u></a></strong>. Тафома е достапна и на мобилен, сѐ што треба да направите е да кликнете на линковите подолу за да ја преземете апликацијата.</p>
                                    <p><a href="https://apps.apple.com/us/app/tafoma/id1137130236">App Store</a></p>
                                    <p><a href="https://play.google.com/store/apps/details?id=tafoma.com">Play Store </a></p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Како да креирате профил?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <ul>
                                        <li>За да започнете, одете на веб страницата на <strong><a href="https://app.tafoma.com"> Тафома </a></strong>и кликнете на копчето Регистрирај Cе.</li>
                                        <li>Сега можете да ги внесете вашите лични податоци.</li>
                                        <li>Веќе сте таму!</li>
                                    </ul>
                                    <p><sub>*The Workspace name you set is for your personal workspace. <a href="https://tafoma.com/help-center/">More about Workspaces</a>.</sub></p>
                                    <p><img src="https://tafoma.com/wp-content/uploads/2020/12/ezgif.com-video-to-gif.gif" alt="" width="403" height="250" /></p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            {/* <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Creating a Workspace
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Creating a Workspace in Tafoma is easy, but it is important to understand how to mange more Workspaces, we recommend you check out this article on Workspaces.</p>
                                    <ul>
                                        <li>To create a Workspace go into Tafoma and click on the <strong>More</strong> tab.</li>
                                        <li>Than click on&nbsp;<strong>Workspaces</strong></li>
                                        <li>In the new window that opened, click <strong>New Workspace</strong></li>
                                        <li>Enter the name and click <strong>Create</strong>&nbsp;</li>
                                    </ul>
                                    <p><img src="https://tafoma.com/wp-content/uploads/2020/12/ScreenFlow.gif" alt="" width="487" height="300" /></p>
                                    <ul>
                                        <li>Tafoma transfers you into the new workspace and you can start inviting team members (invite Members).</li>
                                    </ul>
                                </AccordionItemPanel>
                            </AccordionItem> */}

                            {/* <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Inviting Members
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>In order for Tafoma to help you with Team-Collaboration, you need to invite your team members to one Workspace.</p>
                                    <ul>
                                        <li>Inviting someone to Tafoma can be done from the <strong>+</strong> or from the Teams tab.</li>
                                        <li>All you need to do is <strong>enter their email address</strong> and select a team from which they are part of. (not mandatory)</li>
                                        <li>The new member that you invited will receive an email that will take them to the Sing-Up page and after they Sign-Up it will take them to the workspace. If the person is already registered in Tafoma alongside the email they will also receive a notification in Tafoma.</li>
                                        <li>Bellow that you can see the Permissions that you can allow this member to have, Read More about Permissions bellow.</li>
                                    </ul>
                                    <p><img src="https://tafoma.com/wp-content/uploads/2020/12/ScreenFlow2.gif" alt="" width="406" height="250" /></p>
                                    <h4>Permissions</h4>
                                    <p>When inviting a member or editing a member that has been invited by you, you can chose what things they can work on.</p>
                                    <p>Having the <strong>Project</strong> Permission off means that they can&rsquo;t make Projects in the Workspace. They will be able to see projects which are shared with them. <br />The same applies to <strong>Contacts</strong> and <strong>Tasks</strong>.</p>
                                    <p><br /><strong>Invite Members</strong>, this allows/disallows the new member to invite other members to the workspace. (We recommend turning this ON only for team-leaders)</p>
                                    <p><br /><strong>See Other Members</strong>, here you chose if the new member can see the Other Members in the workspace.</p>
                                    <p><br /><strong>External Followers</strong>, this allows/disallows the new member to invite external followers on task or projects.</p>
                                </AccordionItemPanel>
                            </AccordionItem> */}

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Кои се бенефитите од користењето на неинтегративна платформа за тимска комуникација и менаџирање со проекти како Тафома?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Во суштина, Тафома како софтвер за менаџирање со проекти и тимска комуникација се користи во бројни индустрии за планирање на проекти, менаџирање со времето, подобрување на комуникацијата, распределба на ресурси и управување со промени.</p>
                                    <p>Сепак, неинтегративната функција на Тафома овозможува економичност и беспрекорна архитектура која им помага на малите и средните компании да станат што е можно попродуктивни и конкурентни и ги издвојува од останатиот дел од индустријата.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Која е разликата помеѓу алатката Проекти и алатката Задачи на Тафома?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Управувањето со проекти и управувањето со задачи на Тафома одат рака под рака.</p>
                                    <p>Проектите на Тафома се пристапна и ефикасна алатка, каде што можете да видите како се одвива проектот - од почеток па се до завршување, тие содржат пресвртници и јасен исход. Тоа ви дава преглед на сите задачи.</p>
                                    <p>Задачите се единечни алатки за работа, на индивидуално ниво кои ви помагаат за да се заврши некој проект. Задачите на Тафома може да се состојат од: опис, коментари, списоци со задачи, трошоци и дел за менаџирање со времето.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Каква е мобилната верзија на Тафома?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>При дизајнирањето на нашиот мобилен интерфејс Тафома, посветивме особено внимание на голем број принципи за интуитивна интеракција: соодветност за задачата, компатибилност, конзистентност, гешталт закони (како умот групира слични елементи заедно, врз основа на нивната форма, боја, големина и осветленост), повратна информација и самоописливост. Нашата неинтегративна платформа, исто така, не бара синхронизација со софтвер од трета страна што овозможува беспрекорна работа на платформата.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Колку се безбедни моите датотеки и документи и каква е политиката за заштита на податоците на Тафома?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Во согласност со он-лајн политиките за приватност, се гордееме што Тафома обезбедува шифрирање на двојно ниво, така што вашите собрани, споделени и зачувани информации остануваат приватни и безбедни. Нашиот SSL сертификат нуди целосна заштита на податоците.</p>
                                </AccordionItemPanel>
                            </AccordionItem>

                        </Accordion>
                    </div>
                </div>
            </div>
            <StartProject />
            <Footer />
        </Layout>
    );
}

export default FAQ